/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui'
import { FC } from 'react'
import { CollectionTemplateType } from '~/@types/models'
import CollectionLayout from '~/components/layout/collection-layout'
import CollectionProducts from '~/containers/collection-products'
import ProductItem from '~/components/product-item'

type Props = CollectionTemplateType

const CollectionLifestyle: FC<Props> = ({ pageContext: { data } }) => {
  return (
    <CollectionLayout
      title={data.title}
      description={data.description}
      ankleContent={data.metafields?.ankleContent}
      heroHeader={{
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
      catalog={data.catalog}
    >
      <CollectionProducts
        collectionHandle={data.handle}
        initialData={data.products}
        pagination={data.pagination}
        data={data}
        gridVariant="layout.template-default"
      >
        {props => (
          <Grid variant="layout.template-default" className="product-list-lifestyle">
            {props.products.map(item => (
              <Box key={item.handle} mb={120}>
                <ProductItem
                  product={{
                    ...item,
                    mainImage: item.mainImageLifestyleOriginal,
                  }}
                  productNote={item.productNote}
                  imageSize="FULLSCREEN"
                  gridLayout="template-default"
                />
              </Box>
            ))}
          </Grid>
        )}
      </CollectionProducts>
    </CollectionLayout>
  )
}

export default CollectionLifestyle
